@use "Library";

@mixin import {
  #menacePage {
    @include Library.mainPageFormat();
    .menaceGame {
      .buttonArea {
        display: flex;
        .validButtons {
          border: 1px solid black;
          text-align: center;
          width: 20px;
          cursor: pointer;
        }
        :hover {
          background-color: lightgray;
        }
      }
      .tttDisplay {
        display: grid;
        grid-template-columns: repeat(3, 50px);
        grid-template-rows: repeat(3, 50px);
        p {
          margin: 0;
          text-align: center;
          font-size: 30px;
        }
      }
      .allPositions {
        border: 1px solid black;
        border-radius: 10px;
        padding-left: 10px;
        margin-top: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        .individualPosition {
          height: 160px;
          border: 1px solid black;
          border-radius: 10px;
          padding: 10px;
          display: flex;
          align-items: center;
          .beadArea {
            display: flex;
            flex-wrap: wrap;
            margin-left: 10px;
            p {
              margin: 0;
              border: 2px solid black;
              border-radius: 5px;
              padding: 4px;
            }
          }
        }
      }
    }
  }
}