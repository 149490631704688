@use "Home"; @use "Sidebar"; @use "SimHome"; @use "NBoxDilemma";
@use "Menace";

html, body {
  padding: 0;
  margin: 0;
  height: 100vh;
  font-family: "Inter", sans-serif;
}
#app {
  display: grid;
  grid-template-columns: 120px 1fr;
  height: 100vh;
}

#innerPage {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

@include Home.import();
@include Sidebar.import();
@include SimHome.import();
@include NBoxDilemma.import();
@include Menace.import();